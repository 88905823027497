import React from "react"
import { graphql } from "gatsby"
import T from "prop-types"
import marked from "marked"
import Container from "components/Container"
import Layout from "components/Layout"
import Title from "components/Title"
import TitleBlock from "components/Blocks/TitleBlock"
import Img from "gatsby-image/withIEPolyfill"
import styles from "./Locations.module.css"
import altStyles from "../templates/WhatWeDo.module.css"
import HubspotForm from "components/HubspotForm"

// eslint-disable-next-line
export const LocationsPage = ({ data, ...props }) => (
  <Layout {...props} {...data}>
    <Container className={altStyles.wrapper}>
      <div>
        <Title className={`${altStyles.title} ${altStyles.titlesmall}`}>
          <span>{data.title}</span>
        </Title>
        <div className={altStyles.description} dangerouslySetInnerHTML={{ __html: marked(data.description || "") }} />
      </div>
        <div className={altStyles.image}>
          <Img
            loading="lazy"
            objectFit="contain"
            key={data.title}
            alt={data.title}
            title={data.title}
            fluid={data.image.childImageSharp.fluid}
          />
        </div>
    </Container>

    <Container className={styles.image}>
      <Img
        loading="lazy"
        objectFit="cover"
        key={data.title}
        alt={data.title}
        title={data.title}
        fluid={data.bannerImage.childImageSharp.fluid}
      />
    </Container>

    <Container>
      <ul className={styles.locations}>
        {data.locations.map((location, i) => (
          <li key={i}>
            <h3 dangerouslySetInnerHTML={{ __html: marked(location.title) }} />
            <address dangerouslySetInnerHTML={{ __html: marked(location.address) }} />
          </li>
        ))}
      </ul>
    </Container>
    <div className={altStyles.grayBackground} id="contact-form">
      <Container>
      <div className={altStyles.titleContainer}>
          <TitleBlock isLarger><span className={altStyles.preTitle}>Contact Us</span>Learn more about how OpsGuru can help you with your cloud journey.</TitleBlock>
        </div>
        <HubspotForm id="b586e1c3-2aa2-4cce-815e-a8fb546726bb" portalId="6237410" />
      </Container>
    </div>
  </Layout>
)

LocationsPage.propTypes = {
  data: T.shape({
    title: T.string.isRequired,
    image: T.object.isRequired,
    locations: T.arrayOf(
      T.shape({
        title: T.string.isRequired,
        address: T.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
}

// eslint-disable-next-line
export const LocationsPageWrapper = (props) => (
  <LocationsPage {...props} data={props.data.markdownRemark.frontmatter} />
)

export default LocationsPageWrapper

export const pageQuery = graphql`
  query LocationsPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "locations" } }) {
      frontmatter {
        seo {
          title
          description
          canonical
          image {
            extension
            publicURL
            childImageSharp {
              fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        title
        description
        bannerImage {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        locations {
          title
          address
        }
      }
    }
  }
`
